<template>
  <div
    class="ui-height-easing"
    :class="{ 'is-no-overflow': noOverflow }"
    :style="{
      '--height': heightPx,
    }"
  >
    <div ref="contentRef" class="ui-height-easing__inner">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useResizeObserver } from '@vueuse/core';

export default defineComponent({
  props: {
    noOverflow: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const contentRef = ref<HTMLElement | null>(null);
    const height = ref<number | null>(null);
    const heightPx = computed(() => `${height.value}px`);

    useResizeObserver(contentRef, ([{ contentRect }]) => (height.value = contentRect.height));

    return {
      contentRef,
      heightPx,
    };
  },
});
</script>

<style lang="scss" scoped>
.ui-height-easing {
  height: var(--height);

  //transition: height $speed-slow $easing;

  &.is-no-overflow {
    overflow: hidden;
  }
}
</style>
