<template>
  <div
    v-click-outside="onClickOutside"
    class="pt-select"
    :class="{
      'is-open': dropdownOpen,
      'is-disabled': disabled,
      [`is-${size}`]: size,
    }"
  >
    <div
      :id="`pt-select${uid}__combobox`"
      ref="toggle"
      class="pt-select__dropdown-toggle"
      role="combobox"
      :aria-expanded="dropdownOpen"
      :aria-owns="`pt-select${uid}__listbox`"
      @mousedown="toggleDropdown($event)"
    >
      <div ref="selectedOptions" class="pt-select__selected-options">
        <template v-if="selectedValue.length">
          <slot v-for="option in selectedValue" name="selected-option-container" :option="normalizeOptionForSlot(option)" :disabled="disabled">
            <span :key="getOptionKey(option)" class="pt-select__selected">
              <slot name="selected-option" v-bind="normalizeOptionForSlot(option)">
                {{ getOptionLabel(option) }}
              </slot>
            </span>
          </slot>
        </template>
        <span v-else-if="placeholder" class="pt-select__placeholder">
          {{ placeholder }}
        </span>
      </div>

      <div ref="actions" class="pt-select__actions">
        <button
          v-show="showClearButton"
          ref="clearButton"
          :disabled="disabled"
          type="button"
          class="pt-select__clear"
          title="Clear Selected"
          aria-label="Clear Selected"
          @click="updateValue(null)"
        >
          <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.7125 0.909912L1.51758 8.99993" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M1.51758 0.909912L9.7125 8.99993" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" />
          </svg>
        </button>
        <span v-if="!noDrop" ref="openIndicator" class="pt-select__open-indicator" :class="{ reverse: open }" role="presentation">
          <pt-icon name="chevron-down" :size="12" />
        </span>
      </div>
    </div>
    <height-easing class="pt-select__dropdown-wrapper">
      <transition name="pt-select__fade">
        <ul
          v-if="dropdownOpen"
          :id="`vs${uid}__listbox`"
          ref="dropdownMenu"
          :key="`vs${uid}__listbox`"
          class="pt-select__dropdown-menu"
          role="listbox"
          tabindex="-1"
        >
          <li
            v-if="emptyOption !== null"
            :id="`pt-select${uid}__option-empty`"
            role="option"
            class="pt-select__dropdown-option"
            :class="{ 'is-selected': isOptionSelected(emptyOption) }"
            @click.prevent.stop="select(emptyOption)"
          >
            <slot name="emptyOption" v-bind="normalizeOptionForSlot(emptyOption)">
              {{ getOptionLabel(emptyOption) }}
            </slot>
          </li>
          <li
            v-for="(option, index) in options"
            :id="`pt-select${uid}__option-${index}`"
            :key="getOptionKey(option)"
            role="option"
            class="pt-select__dropdown-option"
            :class="{ 'is-selected': isOptionSelected(option) }"
            @click.prevent.stop="select(option)"
          >
            <slot name="option" v-bind="normalizeOptionForSlot(option)">
              {{ getOptionLabel(option) }}
            </slot>
          </li>
        </ul>
        <ul v-else :id="`vs${uid}__listbox`" role="listbox" style="display: none; visibility: hidden" />
      </transition>
    </height-easing>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs, watch } from 'vue';

import type { LooseObject, Nullable } from '@/types/generic';
import { clickOutside } from '@/directives/click-outside';
import { uniqueId } from '~/utils/uniq';
import HeightEasing from '~/components/global/elements/HeightEasing.vue';

type SelectSize = '' | 'smaller';
type SelectOption = LooseObject | string;

const sortAndStringify = (sortable: LooseObject): string => {
  const ordered: LooseObject = {};
  Object.keys(sortable)
    .sort()
    .forEach((key) => {
      ordered[key] = sortable[key];
    });

  return JSON.stringify(ordered);
};

export default defineComponent({
  components: { HeightEasing },
  directives: {
    clickOutside,
  },

  props: {
    modelValue: {
      type: [Object, String, Number] as PropType<SelectOption>,
      default: null,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      default: (): SelectOption[] => [],
    },
    optionIdKey: {
      type: [String, null],
      default: null,
    },
    emptyOption: {
      type: [String, Object] as PropType<SelectOption[]>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    label: {
      type: String,
      default: 'label',
    },
    reduce: {
      type: Function,
      default: (option: SelectOption): SelectOption => option,
    },
    noDrop: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<SelectSize>,
      default: '',
    },
    uid: {
      type: [String, Number],
      default: () => uniqueId(),
    },
  },
  emits: ['update:modelValue', 'open', 'close', 'option:selecting', 'option:selected'],

  setup(props, context) {
    const { noDrop, clearable, options, reduce, disabled, label } = toRefs(props);

    const propModelValue = computed(() => props.modelValue);

    const modelValue = ref(props.modelValue);
    const clearButton: Ref<Nullable<HTMLElement>> = ref(null);

    const open = ref(false);
    const isComposing = ref(false);
    const internalValue: Ref<Nullable<SelectOption>> = ref(null);

    const dropdownOpen = computed(() => (noDrop.value ? false : open.value));
    const isValueEmpty = computed(() => selectedValue.value.length === 0);
    const showClearButton = computed(() => clearable.value && !open.value && !isValueEmpty.value);

    const getOptionLabel = (option: SelectOption): string => {
      if (typeof option === 'object') {
        return option[label.value];
      }
      return option;
    };

    const getOptionKey = (option: SelectOption): string => {
      if (typeof option !== 'object') {
        return option;
      }
      return option.id || option[props.optionIdKey] || sortAndStringify(option);
    };

    const selectedValue = computed(() => {
      return internalValue.value ? [internalValue.value] : [];
    });

    const findOptionFromReducedValue = (value: SelectOption) => {
      const matches: SelectOption[] = options.value.filter((option) => JSON.stringify(reduce.value(option)) === JSON.stringify(value));

      if (matches.length === 1) {
        return matches[0];
      }

      return value;
    };

    const setInternalValueFromOptions = (value: SelectOption) => {
      internalValue.value = findOptionFromReducedValue(value);
    };

    const updateValue = (value: Nullable<SelectOption>) => {
      if (typeof value === 'undefined') {
        // Vue select has to manage value
        internalValue.value = value;
      }

      const newValue = value === null ? value : reduce.value(value);
      modelValue.value = newValue;

      context.emit('update:modelValue', newValue);
    };

    const toggleDropdown = (event: Event) => {
      const ignoredButtons = clearButton.value ? [clearButton.value] : [];
      if (
        ignoredButtons
          .filter(Boolean)
          .some((ref) => event.target && (ref.contains(event.target as HTMLElement) || ref === (event.target as HTMLElement)))
      ) {
        event.preventDefault();
        return;
      }

      if (open.value) {
        open.value = false;
      } else if (!disabled.value) {
        open.value = true;
      }
    };

    const select = (option: SelectOption): void => {
      context.emit('option:selecting', option);
      if (!isOptionSelected(option)) {
        updateValue(option);
        context.emit('option:selected', option);
      }
      open.value = !open.value;
    };

    const isOptionSelected = (option: SelectOption): boolean => {
      return selectedValue.value.some((value) => optionComparator(value, option));
    };

    const optionComparator = (a: SelectOption, b: SelectOption) => {
      return getOptionKey(a) === getOptionKey(b);
    };

    const normalizeOptionForSlot = (option: SelectOption): SelectOption => {
      return typeof option === 'object' ? option : { [label.value]: option };
    };

    const onClickOutside = () => {
      open.value = false;
    };

    watch(options, () => {
      if (modelValue.value) {
        setInternalValueFromOptions(modelValue.value);
      }
    });

    watch(modelValue, () => {
      setInternalValueFromOptions(modelValue.value);
    });

    watch(propModelValue, (val) => {
      if (val !== modelValue.value) {
        modelValue.value = val;
      }
    });

    watch(open, (val) => {
      context.emit(val ? 'open' : 'close');
    });

    if (typeof modelValue.value !== 'undefined') {
      setInternalValueFromOptions(modelValue.value);
    }

    return {
      select,
      clearButton,
      open,
      isComposing,
      internalValue,
      selectedValue,
      dropdownOpen,
      updateValue,
      isOptionSelected,
      normalizeOptionForSlot,
      showClearButton,
      toggleDropdown,
      getOptionLabel,
      getOptionKey,
      onClickOutside,
    };
  },
});
</script>
